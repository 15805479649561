import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import Modal from 'react-modal';
import { modalStyles } from '../utils/styles';
import { saveUserFiles } from '../services/users';
import Swal from 'sweetalert2';

const AddUserFile = ({ modal, setModal, user, updateUserFiles }) => {
    const [files, setfiles] = useState([]);

    const { getRootProps, getInputProps } = useDropzone({
        accept: { "image/*": [], "application/pdf": [], "text/csv": [] },
        onDrop: (acceptedFiles) => { setfiles(s => s ? [...s, ...acceptedFiles] : acceptedFiles); }
    });

    const deleteFile = (idx) => {
        setfiles(s => s.filter((_, i) => i !== idx));
    };

    const submit = async () => {
        try {
            setModal(false);
            const res = await saveUserFiles(files, user);
            if (!res?.success) { throw new Error(); }

            updateUserFiles(res.files);

            Swal.fire({
                title: 'Archivo subido',
                text: 'El archivo se ha añadido correctamente',
                icon: 'success',
                buttonsStyling: false,
                customClass: {
                    confirmButton: "btn btn-primary",
                }
            });
        } catch(error) {
            Swal.fire({
                title: 'Hubo un problema',
                text: 'No pudimos realizar la operación',
                icon: 'error',
                buttonsStyling: false,
                customClass: {
                    confirmButton: "btn btn-danger",
                }
            });
        }
    };

    return (
        <Modal
            isOpen={modal}
            onRequestClose={() => setModal(false)}
            style={modalStyles}
            contentLabel="Subir archivos"
        >
            <div className="mt-1">
                <label>Añadir archivos</label>
                <section>
                    <div {...getRootProps({ className: "dropzone" })}>
                        <input {...getInputProps()} />
                        <small className="my-0 px-1 text-small text-center">
                            Arrastra y suelta tu archivo aquí o haga clic para seleccionar.
                        </small>
                    </div>
                    <div className='row d-flex justify-content-center justify-content-sm-start  my-1'>
                        {files?.map((file, i) =>
                            <div key={i} className="col-12">
                                <ul class="list-group">
                                    <li className="list-group-item list-group-item-action d-flex justify-content-between align-items-center">{file.name}
                                        <div>
                                            <button type="button" className="btn btn-primary btn-md text-white" onClick={() => deleteFile(i)} >
                                                <i color={'white'} size={20} className='fa fa-trash' />
                                            </button>
                                        </div>
                                    </li>
                                </ul>
                            </div >
                        )}
                    </div>
                </section>
                <div className="modal-footer col-12 d-flex justify-content-end">
                    <button type="button" className="btn btn-secondary btn-md active me-3" onClick={() => setModal(false)}>Cancelar</button>
                    <button type="button" className="btn btn-primary btn-md text-white" onClick={submit}>Subir</button>
                </div>
            </div>
        </Modal>
    );
};

export default AddUserFile;
