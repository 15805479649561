import React, { useState } from 'react';
import Modal from 'react-modal';
import { modalStyles } from '../utils/styles';
import Swal from 'sweetalert2';
import { createExpense } from '../services/expenses';

const AddUserExpense = ({ modal, setModal, user, onEnd }) => {
    const [data, setData] = useState({
        user,
        amount: 0,
        currency: 'USD',
        description: '',
    });

    const submit = async () => {
        try {
            setModal(false);
            const res = await createExpense(data);

            if (!res?.success) { throw new Error(res.message); }

            onEnd();

            Swal.fire({
                title: 'Exito',
                text: 'Expensa añadida.',
                icon: 'success',
                buttonsStyling: false,
                customClass: {
                    confirmButton: "btn btn-primary",
                }
            });
        } catch(error) {
            Swal.fire({
                title: 'Hubo un problema',
                text: error.message || 'No pudimos realizar la operación',
                icon: 'error',
                buttonsStyling: false,
                customClass: {
                    confirmButton: "btn btn-danger",
                }
            });
        }
    };

    return (
        <Modal
            isOpen={modal}
            onRequestClose={() => setModal(false)}
            style={modalStyles}
            contentLabel="Añadir expensa"
        >
            <div className="mt-1">
                <div className='modal-header'>
                    <h3>Añadir expensa</h3>
                </div>
                <div className='modal-body'>
                    <div className="form-group">
                        <label htmlFor="amount">Monto</label>
                        <input type="number" className="form-control" id="amount" onChange={(e) => setData((s) => ({ ...s, amount: e.target.value }))} value={data.amount} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="currency">Moneda</label>
                        <select className="form-control" id="currency" onChange={(e) => setData((s) => ({ ...s, currency: e.target.value }))} value={data.currency} >
                            <option value='USD'>USD</option>
                            <option value='ARS'>ARS</option>
                            <option value='CRD'>CRD</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="description">Descripción</label>
                        <input type="text" className="form-control" id="description" onChange={(e) => setData((s) => ({ ...s, description: e.target.value }))} value={data.description} />
                    </div>
                </div>
                <div className="modal-footer mt-3 col-12 d-flex justify-content-end">
                    <button type="button" className="btn btn-secondary btn-md active me-3" onClick={() => setModal(false)}>Cancelar</button>
                    <button type="button" className="btn btn-primary btn-md text-white" onClick={submit}>Añadir</button>
                </div>
            </div>
        </Modal>
    );
};

export default AddUserExpense;
