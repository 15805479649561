import authReducer from './auth';
import loadingReducer from './loading';
import brandReducer from './brand';
import {combineReducers, createStore} from 'redux';

const rootReducers = combineReducers({
    auth: authReducer,
    loading: loadingReducer,
    brand: brandReducer,
});

const store = createStore(rootReducers);

export default store;