import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { BrowserRouter } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import Error500 from './components/assets/Error500';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <BrowserRouter>
        <ErrorBoundary fallbackRender={Error500}>
            <App />
        </ErrorBoundary>
    </BrowserRouter>
);

serviceWorkerRegistration.unregister();
