import React, { forwardRef, useState, useEffect, Component } from 'react';
import { connect } from 'react-redux';
/* REACT DATE RANGE */
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
/* REACT MODAL */
import Modal from 'react-modal';
import { modalStyles } from '../../utils/styles';

Modal.setAppElement(document.getElementById('root'));

class HiddenDate extends Component {
    constructor(props) {
        super(props);
        this.state = { ...props };
    }

    componentWillReceiveProps(props) {
        this.setState({ ...props });
    }

    render() {
        return null;
    }
}

const DateRange = forwardRef((props, ref) => {
    const [nextDate, setNextDate] = useState({ start: null, end: null });
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectionRange, setSelectionRange] = useState({
        startDate: nextDate.start,
        endDate: nextDate.end,
        key: 'selection',
    });

    const handleSelect = ranges => {
        setNextDate( { start: ranges.selection.startDate, end: ranges.selection.endDate });
    };

    const applyFilter = () => {
        setModalIsOpen(false);
        props.applyFilter();
    };

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setNextDate({ start: props.selectedStartDate, end: props.selectedEndDate });
        props.applyFilter();
    };

    const removeFilter = () => {
        setModalIsOpen(false);
        setNextDate({ start: null, end: null });
        props.onChange();
        props.applyFilter({ start: null, end: null });
    };

    useEffect(() => {
        setSelectionRange({
            startDate: nextDate.start,
            endDate: nextDate.end,
            key: 'selection'
        });
    }, [nextDate]);

    return (
        <div className="d-inline">
            <HiddenDate ref={ref} {...nextDate} />
            <button className={`btn btn-${(nextDate.start && nextDate.end) ? 'primary' : 'light'} btn-block btn-xs btn-icon btn-icon-left`} onClick={openModal} disabled={props.disable}>
                <i className="ico far fa-calendar-alt"></i>
                {
                    (nextDate.start && nextDate.end) ?
                        `${ nextDate.start.toLocaleDateString(props.locale) } - ${ nextDate.end.toLocaleDateString(props.locale) }`
                        :
                        "Not Set"
                }
            </button>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={modalStyles}
                shouldCloseOnOverlayClick={false}
                contentLabel="Modal Date Range Picker"
            >
                <div className="row justify-content-center align-items-center">
                    <DateRangePicker
                        ranges={[selectionRange]}
                        onChange={handleSelect}
                        // .current.props.ranges.startDate
                    />
                </div>
                <hr />
                <div className="row justify-content-center align-items-center">
                    <div className="col-4 text-center">
                        <button className="btn btn-light" onClick={closeModal}>Cancel</button>
                    </div>
                    <div className="col-4 text-center">
                        <button className="btn btn-danger" onClick={removeFilter}>Remove</button>
                    </div>
                    <div className="col-4 text-center">
                        <button className="btn btn-primary" onClick={applyFilter}>Apply Filter</button>
                    </div>
                </div>
            </Modal>
        </div>
    );
});

const mapStateToProps = state => ({ locale: state.auth.user.locale });

export default connect(mapStateToProps, null, null, { forwardRef: true })(DateRange);