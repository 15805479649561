import axios from 'axios';
import Store from '../reducers';
const expired = 'TokenExpiredError';

const CancelToken = axios.CancelToken;
export let cancel;
export let cancelMovements;
export let cancelList;
export let cancelListFromUserDetails;

export const editBooking = async (id, body) => {
    try {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/booking/update/${id}`, body);

        return res.data;
    } catch(err) {
        if (err.response && err.response.data.error === expired) {
            Store.dispatch({ type: 'LOGOUT' });
        }
        return err.response.data;
    }
};

export const createBooking = async (body) => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/booking/create`, body);

        return res.data;
    } catch(err) {
        if (err.response && err.response.data.error === expired) {
            Store.dispatch({ type: 'LOGOUT' });
        }
        return err.response.data;
    }
};

export const getBookingById = async (id) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_API_URL}/booking/${id}`,
            {
                cancelToken: new CancelToken(function executor(c) {
                    cancel = c;
                })
            }
        );

        return res.data;
    } catch(err) {
        if (err.response && err.response.data.error === expired) {
            Store.dispatch({ type: 'LOGOUT' });
        }
        return err.response.data;
    }
};

export const deleteBooking = async (id) => {
    try {
        const res = await axios.delete(`${process.env.REACT_APP_API_URL}/booking/${id}`);
        return res.data;
    } catch(err) {
        if (err.response && err.response.data.error === expired) {
            Store.dispatch({ type: 'LOGOUT' });
        }
        return err.response.data;
    }
};

export const listBookingFromUserDetail = async ({ results, offset, sort, direction, filters }) => {
    const body = {
        sort,
        direction,
        filters
    };

    try {
        const res = await axios.post(
            `${process.env.REACT_APP_API_URL}/booking/getAllFromUserDetail?results=${results}&offset=${offset}`,
            body,
            {
                cancelToken: new CancelToken(function executor(c) {
                    cancelListFromUserDetails = c;
                })
            }
        );

        return res?.data;
    } catch(err) {
        if (err?.response && err.response?.data?.error === expired) {
            Store.dispatch({ type: 'LOGOUT' });
        }
        return err?.response?.data;
    }
};

export const listBooking = async ({ results, offset, sort, direction, filters }) => {
    const body = {
        sort,
        direction,
        filters
    };

    try {
        const res = await axios.post(
            `${process.env.REACT_APP_API_URL}/booking/getAll?results=${results}&offset=${offset}`,
            body,
            {
                cancelToken: new CancelToken(function executor(c) {
                    cancelList = c;
                })
            }
        );

        return res.data;
    } catch(err) {
        if (err.response && err.response.data.error === expired) {
            Store.dispatch({ type: 'LOGOUT' });
        }
        return err.response.data;
    }
};

export const getBookingsByUser = async (id) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_API_URL}/booking/getByUser/${id}`,
            {
                cancelToken: new CancelToken(function executor(c) {
                    cancel = c;
                })
            }
        );

        return res.data;
    } catch(err) {
        if (err.response && err.response.data.error === expired) {
            Store.dispatch({ type: 'LOGOUT' });
        }
        return err.response.data;
    }
};

export const checkBookingAvailability = async (body) => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/booking/checkAvailability`, body,);

        return res.data;
    } catch(err) {
        if (err.response && err.response.data.error === expired) {
            Store.dispatch({ type: 'LOGOUT' });
        }
        return err.response.data;
    }
};

export const bookingStatusChange = async (id, status) => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/booking/status/${id}`, { status },);

        return res.data;
    } catch(err) {
        if (err.response && err.response.data.error === expired) {
            Store.dispatch({ type: 'LOGOUT' });
        }
        return err.response.data;
    }
};

export const saveFiles = async (files) => {
    const formData = new FormData();

    if (files.length > 0) {
        Array.from(files).forEach(file => {
            formData.append('files', file);
        });
    }

    Store.dispatch({ type: 'LOADING' });
    try {
        const res = await axios.post(
            `${process.env.REACT_APP_API_URL}/booking/files`,
            formData,
        );

        return res.data;
    } catch(err) {
        if (err.response && err.response?.data?.message?.name === expired) {
            Store.dispatch({ type: 'LOGOUT' });
        }
        throw err;
    } finally {
        Store.dispatch({ type: 'LOADED' });
    }
};

export const listMovements = async ({ results, offset, startFilter, endFilter, type }) => {
    try {
        const res = await axios.post(
            `${process.env.REACT_APP_API_URL}/booking/getMovements?results=${results}&offset=${offset}`,
            { startFilter, endFilter, type },
            { cancelToken: new CancelToken(function executor(c) { cancelMovements = c; }) }
        );

        return res.data;
    } catch(err) {
        if (err.response && err.response.data.error === expired) {
            Store.dispatch({ type: 'LOGOUT' });
        }
        return err.response.data;
    }
};
