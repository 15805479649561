import axios from 'axios';
import Store from '../reducers';
const expired = 'TokenExpiredError';

const CancelToken = axios.CancelToken;
export let cancel;
export let cancelList;
export let cancelGetById;

export const getTransactions = async ({ results, offset, sort, direction, filters }) => {
    const body = {
        sort,
        direction,
        filters
    };

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/transactions/getAll?results=${results}&offset=${offset}`, body,
            {
                cancelToken: new CancelToken(function executor(c) {
                    cancelList = c;
                })
            }
        );

        return res.data;
    } catch(err) {
        if (err.response && err.response.data.error === expired) {
            Store.dispatch({ type: 'LOGOUT' });
        }
        return err;
    }
};

export const getTransactionById = async (id) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/transactions/:id`,
            {
                cancelToken: new CancelToken(function executor(c) {
                    cancelGetById = c;
                })
            }
        );

        return res.data;
    } catch(err) {
        if (err.response && err.response.data.error === expired) {
            Store.dispatch({ type: 'LOGOUT' });
        }
        return err;
    }
};

export const edit = async (id, body) => {
    Store.dispatch({ type: 'LOADING' });
    try {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/transactions/${id}`, body);
        Store.dispatch({ type: 'LOADED' });
        return res.data;
    } catch(err) {
        Store.dispatch({ type: 'LOADED' });
        if (err.response && err.response.data.error === expired) {
            Store.dispatch({ type: 'LOGOUT' });
        }
        return err.response.data;
    }
};

export const create = async (body) => {
    Store.dispatch({ type: 'LOADING' });
    try {
        const res = await axios.post(
            `${process.env.REACT_APP_API_URL}/transactions/create`,
            body,
        );
        Store.dispatch({ type: 'LOADED' });
        return res.data;
    } catch(err) {
        Store.dispatch({ type: 'LOADED' });
        if (err.response && err.response.data.error === expired) {
            Store.dispatch({ type: 'LOGOUT' });
        }
        return err.response.data;
    }
};

export const getHistory = async ({ results, offset, sort, direction, filters }) => {
    const body = {
        sort,
        direction,
        filters
    };

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/transactions/getHistory?results=${results}&offset=${offset}`, body,
            {
                cancelToken: new CancelToken(function executor(c) {
                    cancelList = c;
                })
            }
        );

        return res.data;
    } catch(err) {
        if (err.response && err.response.data.error === expired) {
            Store.dispatch({ type: 'LOGOUT' });
        }
        return err;
    }
};
