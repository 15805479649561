import React from 'react';
import Lottie from 'react-lottie';
import * as animationData from './lottie/500.json';

const Error500 = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true, 
        animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <div className="row justify-content-center">
            <div className="col-lg-6 col-xs-12 margin-top-60 p-3">
                <h3 className="text-center mb-5">Ups!</h3>
                <Lottie options={defaultOptions}
                    isStopped={false}
                    isPaused={false}
                    height="auto"
                />
                <p className="text-center mb-5">There is a problem. Please try again later.</p>
                <div className="text-center">
                    <button onClick={() => window.location.replace(window.location.origin)} className="btn btn-primary">Go back</button>
                </div>
            </div>
        </div>
    );
};

export default Error500;
