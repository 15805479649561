import axios from 'axios';
import Store from '../reducers';
const expired = 'TokenExpiredError';

const CancelToken = axios.CancelToken;
export let cancel;
export let cancelNegativeBalance;

export const editExpense = async (id, body) => {
    try {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/expenses/${id}`, body);

        return res.data;
    } catch(err) {
        if (err.response && err.response.data.error === expired) {
            Store.dispatch({ type: 'LOGOUT' });
        }
        return err;
    }
};

export const createExpense = async (body) => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/expenses/create`, body);

        return res.data;
    } catch(err) {
        if (err.response && err.response.data.error === expired) {
            Store.dispatch({ type: 'LOGOUT' });
        }
        return err.response.data;
    }
};


export const getExpenseById = async (id) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/expenses/${id}`);

        return res.data;
    } catch(err) {
        if (err.response && err.response.data.error === expired) {
            Store.dispatch({ type: 'LOGOUT' });
        }
        return err;
    }
};

export const listExpenses = async ({ results, offset, sort, direction, filters }) => {
    const body = {
        sort,
        direction,
        filters
    };

    try {
        const res = await axios.post(
            `${process.env.REACT_APP_API_URL}/expenses/list?results=${results}&offset=${offset}`,
            body,
            {
                cancelToken: new CancelToken(function executor(c) {
                    cancel = c;
                })
            }
        );

        return res.data;
    } catch(err) {
        if (err.response && err.response.data.error === expired) {
            Store.dispatch({ type: 'LOGOUT' });
        }
        return err;
    }
};

export const listNegativeBalance = async ({ results, offset, sort, direction, filters }) => {
    const body = { sort, direction, filters };
    try {
        const res = await axios.post(
            `${process.env.REACT_APP_API_URL}/expenses/list-negative-balance?results=${results}&offset=${offset}`,
            body,
            { cancelToken: new CancelToken(function executor(c) { cancelNegativeBalance = c; }) }
        );
        return res.data;
    } catch(err) {
        if (err.response && err.response.data.error === expired) {
            Store.dispatch({ type: 'LOGOUT' });
        }
        return err;
    }
};
