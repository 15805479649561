const getVideoTutorial = (path) => {
    let bestMatch = null;

    for (let i = 0; i < videotutorials.length; i += 1) {
        if (path === videotutorials[i].path) {
            return videotutorials[i];
        }

        if (videotutorials[i].regex && path.match(videotutorials[i].regex)) {
            bestMatch = videotutorials[i];
        }
    }

    return bestMatch;
};

const videotutorials = [
    {
        path: '/bookings',
        regex: /\/bookings\/*/gi,
        loom_id: '39a2b35b181e403488312a8cfa72106e',
        url: 'https://www.loom.com/share/39a2b35b181e403488312a8cfa72106e?sid=5bd76411-543a-4ecc-b128-d5034975c8f2',
    },
    {
        path: '/transactions',
        regex: /\/transactions\/*/gi,
        loom_id: '120acec07e1244a789fff9bf5ff08597',
        url: 'https://www.loom.com/share/120acec07e1244a789fff9bf5ff08597?sid=17643192-6928-4f86-9787-e96649537e6f',
    },
    {
        path: '/assets',
        regex: /\/assets\/*/gi,
        loom_id: '80db486fb8814bdabebe36321f731d94',
        url: 'https://www.loom.com/share/80db486fb8814bdabebe36321f731d94?sid=c9b81e6e-3fb1-440a-b4b4-d415bd5867df',
    },
    {
        path: '/users',
        regex: /\/users\/*/gi,
        loom_id: 'a375591e60b44288a2c4e1d4911f4bd8',
        url: 'https://www.loom.com/share/a375591e60b44288a2c4e1d4911f4bd8?sid=cd189b28-bab3-4fb0-9bdf-c6a2a727bbd7',
    },
    {
        path: '/plans',
        regex: /\/plans\/*/gi,
        loom_id: '3f08ffbfe9da4b669e76d758eaa11877',
        url: 'https://www.loom.com/share/3f08ffbfe9da4b669e76d758eaa11877?sid=4368e4dd-8425-41c9-baa2-72666253ebda',
    },
    {
        path: '/asset-plans',
        regex: /\/asset-plans\/*/gi,
        loom_id: '3503b3f182b14c1689f83af207d7d7ec',
        url: 'https://www.loom.com/share/3503b3f182b14c1689f83af207d7d7ec?sid=fba01b7c-b632-4f6f-9f37-db57f62fd426',
    },
    {
        path: '/holidays',
        regex: /\/holidays\/*/gi,
        loom_id: 'f33a7826015743c39bdf675c2b127199',
        url: 'https://www.loom.com/share/f33a7826015743c39bdf675c2b127199?sid=3ba9c3ca-e9cf-43f5-b2c5-17b52fbb919d',
    },
];

module.exports = { getVideoTutorial };
