import axios from 'axios';
import Store from '../reducers';
const expired = 'TokenExpiredError';

const CancelToken = axios.CancelToken;
export let cancel;
export let cancelList;

export const editAsset = async (id, body) => {
    try {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/asset/${id}`, body);

        return res.data;
    } catch(err) {
        if (err.response && err.response.data.error === expired) {
            Store.dispatch({ type: 'LOGOUT' });
        }
        return err;
    }
};
export const createAsset = async (body) => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/asset/create`, body);

        return res.data;
    } catch(err) {
        if (err.response && err.response.data.error === expired) {
            Store.dispatch({ type: 'LOGOUT' });
        }
        return err;
    }
};

export const getAssetsByUser = async (body) => {
    try {
        const res = await axios.post(
            `${process.env.REACT_APP_API_URL}/asset/get/byUser`,
            body,
            {
                cancelToken: new CancelToken(function executor(c) {
                    cancel = c;
                })
            }
        );

        return res.data;
    } catch(err) {
        if (err.response && err.response.data.error === expired) {
            Store.dispatch({ type: 'LOGOUT' });
        }
        return err;
    }
};

export const getAssetsByName = async (name = '', plan = '') => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_API_URL}/asset/get/byName?name=${name}&plan=${plan}`,
            {
                cancelToken: new CancelToken(function executor(c) {
                    cancel = c;
                })
            }
        );

        return res.data;
    } catch(err) {
        if (err.response && err.response.data.error === expired) {
            Store.dispatch({ type: 'LOGOUT' });
        }
        return err;
    }
};

export const getAssetById = async (id) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_API_URL}/asset/${id}`,
            {
                cancelToken: new CancelToken(function executor(c) {
                    cancel = c;
                })
            }
        );

        return res.data;
    } catch(err) {
        if (err.response && err.response.data.error === expired) {
            Store.dispatch({ type: 'LOGOUT' });
        }
        return err;
    }
};

export const deleteAsset = async (id) => {
    try {
        const res = await axios.delete(
            `${process.env.REACT_APP_API_URL}/asset/${id}`,
            {
                cancelToken: new CancelToken(function executor(c) {
                    cancel = c;
                })
            }
        );

        return res.data;
    } catch(err) {
        if (err.response && err.response.data.error === expired) {
            Store.dispatch({ type: 'LOGOUT' });
        }
        return err;
    }
};

export const getCategories = async (name) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_API_URL}/asset/get/categories?name=${name}`,
            {
                cancelToken: new CancelToken(function executor(c) {
                    cancel = c;
                })
            }
        );

        return res.data;
    } catch(err) {
        if (err.response && err.response.data.error === expired) {
            Store.dispatch({ type: 'LOGOUT' });
        }
        return err;
    }
};

export const listAssets = async ({ results, offset, sort, direction, filters }) => {
    const body = {
        sort,
        direction,
        filters
    };

    try {
        const res = await axios.post(
            `${process.env.REACT_APP_API_URL}/asset/list?results=${results}&offset=${offset}`,
            body,
            {
                cancelToken: new CancelToken(function executor(c) {
                    cancelList = c;
                })
            }
        );

        return res.data;
    } catch(err) {
        if (err.response && err.response.data.error === expired) {
            Store.dispatch({ type: 'LOGOUT' });
        }
        return err;
    }
};

export const saveImages = async (files) => {
    const formData = new FormData();

    if (files.length > 0) {
        Array.from(files).forEach(file => {
            formData.append('files', file);
        });
    }

    Store.dispatch({ type: 'LOADING' });
    try {
        const res = await axios.post(
            `${process.env.REACT_APP_API_URL}/asset/images`,
            formData,
        );

        return res.data;
    } catch(err) {
        if (err.response && err.response?.data?.message?.name === expired) {
            Store.dispatch({ type: 'LOGOUT' });
        }
        throw err;
    } finally {
        Store.dispatch({ type: 'LOADED' });
    }
};

export const saveFile = async (file) => {
    Store.dispatch({ type: 'LOADING' });

    const formData = new FormData();
    formData.append('files', file);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/asset/file`, formData,);
        return res.data;
    } catch(err) {
        if (err.response && err.response?.data?.message?.name === expired) {
            Store.dispatch({ type: 'LOGOUT' });
        }
        throw err;
    } finally {
        Store.dispatch({ type: 'LOADED' });
    }
};

export const deleteFile = async (url, field, id) => {
    Store.dispatch({ type: 'LOADING' });

    try {
        const res = await axios.delete(`${process.env.REACT_APP_API_URL}/asset/file`, { data: { url, field, id } },);
        return res.data;
    } catch(err) {
        if (err.response && err.response?.data?.message?.name === expired) {
            Store.dispatch({ type: 'LOGOUT' });
        }
        throw err;
    } finally {
        Store.dispatch({ type: 'LOADED' });
    }
};
