/* DEPENDENCIES */
import './styles/main.scss';
import "react-datepicker/dist/react-datepicker.css";
import "react-table-6/react-table.css";
import 'bootstrap/dist/js/bootstrap.bundle';
import React, { useEffect, useState, Suspense, lazy } from 'react';
import {
    Route,
    Routes,
    Navigate,
} from 'react-router-dom';
import { Provider } from 'react-redux';
/* STORE */
import Store from './reducers';
/* COMPONENTS */
import AppContainer from './components/AppContainer';
import UserDetails from './screens/User.Details';
const Disabled = lazy(() => import('./components/assets/Disabled'));
// const Error403 = lazy(() => import('./components/assets/Error403'));
const Error404 = lazy(() => import('./components/assets/Error404'));
const Loader = lazy(() => import('./components/assets/Loader'));
// SCREENS
const Dashboard = lazy(() => import('./screens/Dashboard'));
const Admins = lazy(() => import('./screens/Admins.List'));
const AppAssets = lazy(() => import('./screens/AppAssets'));
const AppAssetsEdit = lazy(() => import('./screens/AppAssets.Edit'));
const Audiences = lazy(() => import('./screens/Audiences'));
const AudiencesNew = lazy(() => import('./screens/Audiences.New'));
const AudiencesView = lazy(() => import('./screens/Audiences.View'));
const AdminsEdit = lazy(() => import('./screens/Admins.Edit'));
const Assets = lazy(() => import('./screens/Assets.List'));
const AssetsEdit = lazy(() => import('./screens/Assets.Edit'));
const BookingList = lazy(() => import('./screens/Booking.List'));
const BookingEdit = lazy(() => import('./screens/Booking.Edit'));
const Holidays = lazy(() => import('./screens/Holidays.List'));
const HolidaysEdit = lazy(() => import('./screens/Holidays.Edit'));
const Login = lazy(() => import('./screens/Login'));
const Movements = lazy(() => import('./screens/Movements'));
const Maintenance = lazy(() => import('./screens/Maintenance.List'));
const MaintenanceEdit = lazy(() => import('./screens/Maintenance.Edit'));
const NegativeBalanceList = lazy(() => import('./screens/NegativeBalance.List'));
const Plans = lazy(() => import('./screens/Plans.List'));
const PlansEdit = lazy(() => import('./screens/Plans.Edit'));
const Users = lazy(() => import('./screens/Users.List'));
const UsersEdit = lazy(() => import('./screens/Users.Edit'));
const Transactions = lazy(() => import('./screens/Transactions.List'));
const TransactionsEdit = lazy(() => import('./screens/Transactions.Edit'));
const AssetCost = lazy(() => import('./screens/AssetCosts.List'));
const AssetCostEdit = lazy(() => import('./screens/AssetCosts.Edit'));
const Notifications = lazy(() => import('./screens/Notifications'));
const NotificationsNew = lazy(() => import('./screens/Notifications.New'));
const NotificationsReads = lazy(() => import('./screens/Notifications.Reads'));
const Contents = lazy(() => import('./screens/Contents'));
const ContentsEdit = lazy(() => import('./screens/Contents.Edit'));
const Reminders = lazy(() => import('./screens/Reminders.List'));
const RemindersEdit = lazy(() => import('./screens/Reminders.Edit'));
const Settings = lazy(() => import('./screens/Settings.List'));
const SettingsEdit = lazy(() => import('./screens/Settings.Edit'));
const SettingsSchedule = lazy(() => import('./screens/SettingsSchedule'));

const App = () => {
    const [auth, setAuth] = useState(Store.getState().auth.isAuthenticated);
    const [isActive, setIsActive] = useState(Store.getState().auth.user?.is_active === false ? false : true);
    const [redirect] = useState(window.location.pathname + window.location.search);

    useEffect(() => {
        const login = Store.subscribe(() => {
            setAuth(Store.getState().auth.isAuthenticated);
            setIsActive(Store.getState().auth.user.is_active);
        });

        return function cleanup() {
            login();
        };
    }, []);

    return (
        <Provider store={Store}>
            <Loader />
            {(isActive === false && auth) && <Disabled />}
            {
                auth
                    ?
                    (
                        <AppContainer>
                            <Suspense fallback={<div className="loader"><div className="typing_loader"></div></div>}>
                                <Routes>
                                    <Route path='/' element={<Dashboard />} />
                                    <Route path='/admins' element={<Admins />} />
                                    <Route path='/app-assets' element={<AppAssets />} />
                                    <Route path='/app-assets/edit/:name' element={<AppAssetsEdit />} />
                                    <Route path='/audiences' element={<Audiences />} />
                                    <Route path='/audiences/create' element={<AudiencesNew />} />
                                    <Route path='/audiences/view/:id' element={<AudiencesView />} />
                                    <Route path='/admins/edit/:id' element={<AdminsEdit />} />
                                    <Route path='/admins/create/' element={<AdminsEdit />} />
                                    <Route path='/assets' element={<Assets />} />
                                    <Route path='/assets/create/' element={<AssetsEdit />} />
                                    <Route path='/assets/edit/:id' element={<AssetsEdit />} />
                                    <Route path='/bookings' element={<BookingList />} />
                                    <Route path='/bookings/create' element={<BookingEdit />} />
                                    <Route path='/bookings/edit/:id' element={<BookingEdit />} />
                                    <Route path='/contents' element={<Contents archived={false} />} />
                                    <Route path='/contents/archived' element={<Contents archived={true} />} />
                                    <Route path='/contents/create' element={<ContentsEdit />} />
                                    <Route path='/contents/edit/:id' element={<ContentsEdit />} />
                                    <Route path='/movements' element={<Movements />} />
                                    <Route path='/assets/maintenance' element={<Maintenance />} />
                                    <Route path='/assets/maintenance/create' element={<MaintenanceEdit />} />
                                    <Route path='/assets/maintenance/edit/:id' element={<MaintenanceEdit />} />
                                    <Route path='/plans' element={<Plans />} />
                                    <Route path='/plans/create' element={<PlansEdit />} />
                                    <Route path='/plans/edit/:id' element={<PlansEdit />} />
                                    <Route path='/login' element={<Login />} />
                                    <Route path='/user/details/:id' element={<UserDetails />} />
                                    <Route path='/users' element={<Users />} />
                                    <Route path='/users/edit/:id' element={<UsersEdit />} />
                                    <Route path='/users/create' element={<UsersEdit />} />
                                    <Route path='/reminders' element={<Reminders />} />
                                    <Route path='/reminders/create' element={<RemindersEdit />} />
                                    <Route path='/reminders/edit/:id' element={<RemindersEdit />} />
                                    <Route path='/transactions' element={<Transactions />} />
                                    <Route path='/transactions/create' element={<TransactionsEdit />} />
                                    <Route path='/transactions/edit/:id' element={<TransactionsEdit />} />
                                    <Route path='/assets/costs' element={<AssetCost />} />
                                    <Route path='/assets/costs/create' element={<AssetCostEdit />} />
                                    <Route path='/assets/costs/edit/:id' element={<AssetCostEdit />} />
                                    <Route path='/negative-balance' element={<NegativeBalanceList />} />
                                    <Route path='/notifications' element={<Notifications />} />
                                    <Route path='/notifications/new' element={<NotificationsNew />} />
                                    <Route path='/notifications/:id/reads' element={<NotificationsReads />} />
                                    <Route path='/holidays' element={<Holidays />} />
                                    <Route path='/holidays/create' element={<HolidaysEdit />} />
                                    <Route path='/holidays/edit/:id' element={<HolidaysEdit />} />
                                    <Route path='/settings' element={<Settings />} />
                                    <Route path='/settings/scheduled' element={<SettingsSchedule />} />
                                    <Route path='/settings/:key/schedule' element={<SettingsEdit />} />
                                    <Route path='/settings/edit/:key' element={<SettingsEdit />} />
                                    <Route path='*' element={<Error404 />} />
                                </Routes>
                            </Suspense>
                        </AppContainer>
                    )
                    :
                    <Routes>
                        <Route path='/login' element={<Login />} />
                        <Route path='/*' element={<Navigate to={`/login${(redirect && redirect.indexOf('login') === -1 && redirect !== '/') ? `?path=${redirect}` : ''}`} />} />
                    </Routes>
            }
        </Provider >
    );
};

export default App;
