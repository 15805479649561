import axios from 'axios';
import Store from '../reducers';
const expired = 'TokenExpiredError';

const CancelToken = axios.CancelToken;
export let cancel;
export let cancelList;

export const getUsers = async ({ results, offset, sort, direction, filters }) => {
    const body = {
        sort,
        direction,
        filters
    };

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/user/getAll?results=${results}&offset=${offset}`, body,
            {
                cancelToken: new CancelToken(function executor(c) {
                    cancelList = c;
                })
            }
        );

        return res.data;
    } catch(err) {
        if (err.response && err.response.data.error === expired) {
            Store.dispatch({ type: 'LOGOUT' });
        }
        return err;
    }
};

export const getUserById = async (id) => {
    Store.dispatch({ type: 'LOADING' });
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/user/${id}`,
            {
                cancelToken: new CancelToken(function executor(c) {
                    cancel = c;
                })
            }
        );
        Store.dispatch({ type: 'LOADED' });
        return res.data;
    } catch(err) {
        Store.dispatch({ type: 'LOADED' });
        if (err.response && err.response.data.error === expired) {
            Store.dispatch({ type: 'LOGOUT' });
        }
        return err.response.data;
    }
};

export const editUser = async (id, body) => {
    Store.dispatch({ type: 'LOADING' });
    try {
        const res = await axios.put(
            `${process.env.REACT_APP_API_URL}/user/${id}`,
            body,
        );
        Store.dispatch({ type: 'LOADED' });
        return res.data;
    } catch(err) {
        Store.dispatch({ type: 'LOADED' });
        if (err.response && err.response.data.error === expired) {
            Store.dispatch({ type: 'LOGOUT' });
        }
        return err.response.data;
    }
};

export const createUser = async (body) => {
    Store.dispatch({ type: 'LOADING' });
    try {
        const res = await axios.post(
            `${process.env.REACT_APP_API_URL}/user/create`,
            body,
        );
        Store.dispatch({ type: 'LOADED' });
        return res.data;
    } catch(err) {
        Store.dispatch({ type: 'LOADED' });
        if (err.response && err.response.data.error === expired) {
            Store.dispatch({ type: 'LOGOUT' });
        }
        return err.response.data;
    }
};

export const toggleStatus = async (id) => {
    Store.dispatch({ type: 'LOADING' });

    try {
        let res = await axios.put(`${process.env.REACT_APP_API_URL}/user/modify/status/${id}`,
            {},
            {
                cancelToken: new CancelToken(function executor(c) {
                    cancel = c;
                })
            }
        );
        Store.dispatch({ type: 'LOADED' });
        return res.data;
    } catch(err) {
        Store.dispatch({ type: 'LOADED' });
        if (err.response && err.response.data.error === expired) {
            Store.dispatch({ type: 'LOGOUT' });
        }
        throw err.response.data;
    }
};

export const getUserByName = async (name = '') => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/user/byName?name=${name}`,
            {
                cancelToken: new CancelToken(function executor(c) {
                    cancel = c;
                })
            }
        );
        return res.data;
    } catch(err) {
        if (err.response && err.response.data.error === expired) {
            Store.dispatch({ type: 'LOGOUT' });
        }
        return err.response.data;
    }
};

export const resetPassword = async (id) => {
    Store.dispatch({ type: 'LOADING' });
    const url = `${process.env.REACT_APP_API_URL}/user/password/${id}`;

    try {
        const request = await axios.patch(url, {},
            {
                cancelToken: new CancelToken(function executor(c) {
                    cancel = c;
                })
            }
        );
        Store.dispatch({ type: 'LOADED' });
        return request.data;
    } catch(err) {
        Store.dispatch({ type: 'LOADED' });
        if (err.response) {
            if (err.response.data.error === expired) {
                Store.dispatch({ type: 'LOGOUT' });
            }
            throw err.response.data;
        }
        throw err;
    }
};

export const saveUserFiles = async (files, user) => {
    Store.dispatch({ type: 'LOADING' });

    const formData = new FormData();
    if (files.length > 0) {
        Array.from(files).forEach(file => {
            formData.append('files', file);
        });
    }

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/user/add-files/${user}`, formData,);
        return res.data;
    } catch(err) {
        if (err.response && err.response?.data?.message?.name === expired) {
            Store.dispatch({ type: 'LOGOUT' });
        }
        throw err;
    } finally {
        Store.dispatch({ type: 'LOADED' });
    }
};

export const deleteUserFile = async (url, id) => {
    Store.dispatch({ type: 'LOADING' });

    try {
        const res = await axios.delete(`${process.env.REACT_APP_API_URL}/user/remove-file`, { data: { url, id } },);
        return res.data;
    } catch(err) {
        if (err.response && err.response?.data?.message?.name === expired) {
            Store.dispatch({ type: 'LOGOUT' });
        }
        throw err;
    } finally {
        Store.dispatch({ type: 'LOADED' });
    }
};

export const getLicenseCategories = async (name) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/user/license-categories?name=${name}`,);
        return res.data;
    } catch(err) {
        if (err.response && err.response.data.error === expired) {
            Store.dispatch({ type: 'LOGOUT' });
        }
        return err;
    }
};
