const brand = (state = {}, action) => {
    switch (action.type) {
    case 'BRAND_DATA':
        state = {
            ...action.payload,
        };
        return state;
    
    case 'CLEAN':
        state = {};
        return state;

    default:
        return state;
    };
};

export default brand;